"use client";

import styles from './search-bar.module.scss';
import SearchIcon from "assets/icons/search.svg";
import LoadingIcon from "assets/icons/loading-spinner.svg";
import { DebounceInput } from 'react-debounce-input';
import { KeyboardEventHandler, MouseEventHandler, ReactNode, RefObject, useId } from 'react';
const SearchBar = ({
  onChange,
  name,
  onKeyDown,
  onButtonClick,
  debounceTimeout = 200,
  initialQuery,
  placeholderText,
  isSearching,
  icon,
  numericOnly = false,
  id,
  list,
  inputRef
}: Props) => {
  const classString = isSearching ? `${styles.searching} ${styles.wrapper}` : styles.wrapper;
  const listID = useId();
  return <div className={classString} data-sentry-component="SearchBar" data-sentry-source-file="index.tsx">
			{!!icon && <div className={styles.iconContainer}>
				{icon}
			</div>}
			<DebounceInput name={name} id={id} inputRef={inputRef} debounceTimeout={debounceTimeout} className={styles.input} placeholder={placeholderText || "Search"} onKeyDown={onKeyDown ? onKeyDown : event => event.key === 'Enter' && (document.activeElement as HTMLElement).blur()} type={numericOnly ? "numeric" : "search"} onChange={onChange ? onChange : () => {}} value={initialQuery} inputMode={numericOnly ? "numeric" : "text"} pattern={numericOnly ? "[0-9]*" : ".*"} list={listID} data-sentry-element="DebounceInput" data-sentry-source-file="index.tsx" />
			<button aria-label="Search Submit" className={styles.submitButton} disabled={isSearching} // besides preventing double clicks, this is a helpful selector for e2e tests
    onClick={onButtonClick ? onButtonClick : () => (document.activeElement as HTMLElement).blur()}>
				{isSearching ? <LoadingIcon /> : <SearchIcon />}
			</button>

			{list?.length > 0 && <datalist id={listID}>
				{list.map(accessionNumber => {
        return <option key={accessionNumber} value={accessionNumber} />;
      })}
			</datalist>}
		</div>;
};
interface Props {
  name?: string;
  id?: string;
  isSearching?: boolean;
  onChange?: any; //TODO figure out what obscure type this needs to be
  onButtonClick?: MouseEventHandler;
  onKeyDown?: KeyboardEventHandler;
  debounceTimeout?: number;
  initialQuery?: string;
  placeholderText?: string;
  numericOnly?: boolean;
  icon?: ReactNode;
  list?: string[];
  inputRef?: RefObject<HTMLInputElement | null>;
}
export default SearchBar;